"use client";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import isNil from "lodash/isNil";
import last from "lodash/last";
import { PathOptions, StyleFunction } from "leaflet";

import { InvestigateMapPropsV2 } from "./util/types";
import {
  MhcGeographyEnum,
  MhcMapFeatureCollection,
  MhcMapProperties,
  MhcTimeSeriesGranularityEnum
} from "graphqlApi/types";

import useMapState from "./util/useMapStateV2";
import { useMapType } from "./util/useMapTypeV2";
import { getReadableGeographyName } from "common/util/geographyHelpers";

import GeoMapV2 from "common/components/GeoMap/GeoMapV2";
import { StateLoadingIndicator } from "common/components/GeoMap/StateLoadingIndicator";
import { InvestigateDropdown } from "../../charts/Investigate/InvestigateDropdown";
import {
  basicPolygonFeatureStyle,
  noDataMapColor,
  polygonFeatureWithoutDataStyle,
  selectedPolygonFeatureStyle
} from "../../GeoMap/mapStyles";
import { InfoIcon } from "../../InfoIcon";
import { InvestigateMapLegendWrapperV2 } from "./InvestigateMapLegendWrapperV2";

export type InvestigateMapV3Props = InvestigateMapPropsV2<MhcMapFeatureCollection>;

/**
 * Renders an Geomap with pickers allowing user to select geography level and map layer
 * Expects data from `map` GQL query
 * Extended from InvestigateChart so that integration and adoption
 *
 * @param props - Map configuration props
 * @returns Map with geography and layer pickers
 *
 * @see MhcMapFeatureCollection
 * @see MhcMapProperties
 *
 * @todo - Update to handle map layer selection
 *       - Support link to report of location
 *       - Support Infobox onclick functionality
 *
 */
const InvestigateMapV3: React.FC<InvestigateMapV3Props> = (props) => {
  const {
    stats,
    colorRangeName,
    granularity,
    geoMapProps,
    preInvestigationContent,
    stackInvestigations,
    defaultGeography,
    customInvestigationLabel,
    defaultStatId,
    postInvestigationContent,
    allowGeographyChange = true,
    containerSpacing = 4,
    fullWidthDropdowns = false,
    layout = "vertical",
    mapHeight = "100%",
    showTitle = false,
    layersHaveCommonLegend,
    fullWidthMap = false
  } = props;

  const {
    geoJsonByGeography,
    loadingGeography,
    minMaxRecord,
    selectedGeoJson,
    selectedLocationId,
    setGeoJsonByGeography,
    setLoadingGeography,
    setMinMaxRecord,
    setSelectedGeoJson,
    setSelectedLocationId
  } = useMapState(props);

  const {
    handleGeographyChange,
    handleStatIdChange,
    mappedOptions,
    selectedGeography,
    selectedStatId,
    siMappedOptions,
    statIds
  } = useMapType({
    geoJsonByGeography,
    layersHaveCommonLegend,
    initialMinMax: minMaxRecord,
    loadingGeography,
    selectedLocationId,
    setGeoJsonByGeography,
    setLoadingGeography,
    setMinMaxRecord,
    setSelectedGeoJson,
    ...props
  });

  const layoutIsHorizontal = layout === "horizontal";
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<string | null>(null);

  useEffect(() => {
    if (isNil(containerRef.current)) return;
    const current = containerRef.current;
    setContainerWidth(`${current.clientWidth}px`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  const featureStyle: StyleFunction<MhcMapProperties> = useCallback(
    (feature) => {
      const properties = feature?.properties as MhcMapProperties;
      const base = (fillColor: string = noDataMapColor) => {
        let dataStyle: PathOptions = { fillColor };
        if (!fillColor) dataStyle = polygonFeatureWithoutDataStyle;
        return {
          ...basicPolygonFeatureStyle,
          ...dataStyle,
          ...(selectedLocationId === properties.slug ? selectedPolygonFeatureStyle : {})
        };
      };
      if (properties.stats && properties.stats.length > 0) {
        return base(
          properties.stats.find(({ identifier }) => identifier === selectedStatId)?.color || ""
        );
      }
      return polygonFeatureWithoutDataStyle;
    },
    [selectedStatId, selectedLocationId]
  );

  const legend = useMemo(() => {
    const selectedSi = stats.find(({ id }) => id === selectedStatId);
    if (isNil(selectedSi)) return;
    const minMaxByStat = minMaxRecord[selectedSi.id];
    if (isNil(minMaxByStat)) return;
    const minMax = minMaxByStat[selectedGeography ?? "state"];
    if (isNil(minMax)) return;
    return (
      <InvestigateMapLegendWrapperV2
        colorRangeName={colorRangeName}
        selectedSi={selectedSi}
        minMax={minMax}
      />
    );
  }, [colorRangeName, minMaxRecord, selectedGeography, selectedStatId, stats]);

  const mapTitle = useMemo(() => {
    if (!showTitle) return "";
    const currentStatIdentifier = stats.find(({ id }) => id === selectedStatId);
    let year = last(currentStatIdentifier?.availableYears ?? []) ?? "";
    if (year && granularity === MhcTimeSeriesGranularityEnum.FiveYearWindow) {
      year = `${year} - ${String(parseInt(year as string) + 4)}`;
    }
    let title = currentStatIdentifier?.fullName ?? "";
    if (year) title += ` (${year})`;
    return title;
  }, [showTitle, selectedStatId, stats, granularity]);

  const memoizedMap = useMemo(() => {
    if (!selectedGeoJson || loadingGeography) return null;
    return (
      <GeoMapV2
        geoJSON={selectedGeoJson as MhcMapFeatureCollection}
        height={mapHeight}
        showInfoBox={props.showInfoBox ?? true}
        selectedFeatureId={selectedLocationId}
        onFeatureClick={setSelectedLocationId}
        selectedDataIdentifier={selectedStatId}
        zoomToFeature
        animateZoom
        featureStyle={featureStyle}
        maxZoom={13}
        legend={legend}
        {...geoMapProps}
      />
    );
  }, [
    props.showInfoBox,
    featureStyle,
    legend,
    loadingGeography,
    mapHeight,
    selectedGeoJson,
    selectedLocationId,
    selectedStatId,
    setSelectedLocationId,
    geoMapProps
  ]);

  const hasNoInvestigations =
    allowGeographyChange && mappedOptions.length <= 1 && statIds.length <= 1;

  return (
    <Stack
      gap={hasNoInvestigations ? 1 : containerSpacing}
      flex={1}
      data-testid="investigate-map-wrapper"
      direction={layoutIsHorizontal ? "row" : "column"}
      position="relative"
      maxWidth="100%"
      ref={containerRef}
    >
      <Box sx={{ width: layoutIsHorizontal ? "50%" : "100%" }} flexGrow={0}>
        {preInvestigationContent}
        <Stack
          sx={{
            gap: 2,
            flex: 1,
            maxWidth: "100%",
            width: containerWidth ?? "100%",
            flexDirection:
              stackInvestigations ?? !layoutIsHorizontal ? "column" : { xs: "column", md: "row" },
            overflow: "hidden",
            flexGrow: 0
          }}
        >
          {allowGeographyChange && mappedOptions.length > 1 && (
            <InvestigateDropdown
              id="geography"
              sx={{
                width: statIds.length > 1 ? { xs: "100%", md: "min-content" } : "100%",
                minWidth: statIds.length > 1 ? { xs: "100%", md: "min-content" } : "100%",
                maxWidth: statIds.length > 1 ? { xs: "100%", md: "min-content" } : "100%",
                flex: 1,
                flexGrow: statIds.length > 1 ? 0 : 1
              }}
              value={
                selectedGeography ? getReadableGeographyName(selectedGeography, true) : "state"
              }
              title={
                <Stack direction="row" gap={1}>
                  Select Map Type
                  <InfoIcon variant="standard" sx={{ my: "auto" }}>
                    Options grayed-out are not available for the selected investigation.
                  </InfoIcon>
                </Stack>
              }
              onChange={handleGeographyChange}
              defaultValue={defaultGeography}
              options={mappedOptions}
              type="Map"
              valueItemIsSelected={({ value, selectedValue }) => {
                if (!value || !selectedValue) return false;
                return selectedValue === getReadableGeographyName(value as MhcGeographyEnum, true);
              }}
              disabled={loadingGeography}
              fullWidth={fullWidthDropdowns}
            />
          )}
          {statIds.length > 1 && (
            <InvestigateDropdown
              id="stat-identifier"
              sx={{
                width: !allowGeographyChange ? "100%" : { xs: "100%", md: "max-content" },
                minWidth: !allowGeographyChange ? "100%" : { xs: "100%", md: "1%" },
                maxWidth: "100%",
                overflow: "hidden",
                flex: 1
              }}
              value={
                selectedStatId
                  ? siMappedOptions.find(({ value = "" }) => value === selectedStatId)?.title ??
                    selectedStatId
                  : defaultStatId
              }
              title={
                <Stack direction="row" gap={1}>
                  {customInvestigationLabel ?? "Select Investigation"}
                  <InfoIcon variant="standard" sx={{ my: "auto" }}>
                    Investigations grayed-out are not available for the selected map type.
                  </InfoIcon>
                </Stack>
              }
              onChange={handleStatIdChange}
              defaultValue={defaultStatId}
              options={siMappedOptions}
              type="Map"
              valueItemIsSelected={({ value, selectedValue }) => {
                if (!value || !selectedValue) return false;
                return selectedValue === value;
              }}
              disabled={loadingGeography}
              disableRule={(value) => {
                const stat = stats.filter((stat) => stat.id === value);
                const availableGeos = stat.length > 0 ? stat[0]?.availableGeographies : [];
                return selectedGeography ? !availableGeos?.includes(selectedGeography) : false;
              }}
              fullWidth={fullWidthDropdowns}
            />
          )}
          {allowGeographyChange && mappedOptions.length <= 1 && defaultGeography && (
            <Typography component="span" fontWeight={600}>
              Map of {getReadableGeographyName(defaultGeography, true)}
            </Typography>
          )}
        </Stack>
        {postInvestigationContent}
      </Box>
      <Stack flex={1} gap={1}>
        {mapTitle && <Typography fontWeight={600}>{mapTitle}</Typography>}
        <Box
          gap={2}
          sx={{ width: fullWidthMap ? "100%" : layoutIsHorizontal ? "50%" : "100%" }}
          flexGrow={1}
        >
          {loadingGeography ? (
            <StateLoadingIndicator
              width="100%"
              loading={true}
              height={mapHeight}
              indicatorHeight={100}
            />
          ) : (
            memoizedMap
          )}
        </Box>
      </Stack>
    </Stack>
  );
};

export default InvestigateMapV3;
